<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="bannerInfo.id ? $t('portal.banner.update') : $t('portal.banner.add')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <!-- Title -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="bannerInfo.title"
                  :label="$t('bannerInfo.title')"
                  :name="$t('bannerInfo.title')"
                  type="text"
                />
              </v-flex>
              <!-- Description -->
              <v-flex
                xs12
                md6>
                <v-textarea
                  v-model="bannerInfo.description"
                  :label="$t('bannerInfo.description')"
                  :name="$t('bannerInfo.description')"
                  type="text"
                />
              </v-flex>
              <v-layout wrap>
                <!-- Issuance img -->
                <v-flex
                  class="text-center"
                  xs12
                  md6
                >
                  <image-picker
                    key="banner-img"
                    :image-width="384"
                    :image-height="120"
                    :image-url="bannerInfo.imageUrl"
                    exceptation-ratio="16:9"
                    max-size-upload="10MB"
                    recommend-dimension="1920x600"
                    @onSelectedImageFile="onSelectBannerImg"
                    @onResetImage="onClickRemoveBannerImg"
                  />
                </v-flex>
                <!-- Description -->
                <v-flex
                  v-if="bannerInfo.type == portalBannerType.SLIDER"
                  xs12
                  md6>
                  <v-text-field
                    v-model="bannerInfo.link"
                    :label="$t('portal.banner.link')"
                    :name="$t('portal.banner.link')"
                    type="text"
                  />
                </v-flex>
                <v-flex
                  v-if="bannerInfo.type == portalBannerType.VERTICAL"
                  xs12
                  sm6
                  md6
                >
                  <v-select
                    v-model="positionSelected"
                    :items="positionBannerVerticalList"
                    :label="$t('bannerVertical.position')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import ToastType from 'enum/toastType'
import PortalBannerType from 'enum/portalBannerType'
import stringUtils from 'utils/stringUtils'
import ImagePicker from 'Components/ImagePicker.vue'
export default {
  components: { ImagePicker },
  data () {
    return {
      valid: false,
      isShowModal: false,
      bannerInfo: {
        id: null,
        title: null,
        imageUrl: null,
        description: null,
        imageFile: null,
        imageSize: null,
        type: null,
        link: null
      },
      media_server: {
        domain: '',
        token: ''
      },
      portalBannerType: {
        SLIDER: PortalBannerType.SLIDER,
        VERTICAL: PortalBannerType.VERTICAL
      },
      positionBannerVerticalList: stringUtils.PositionBannerVerticalList,
      positionSelected: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_TOKEN_MEDIA_SERVER_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.media_server.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.media_server.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    }
  },
  created () {
    this.GET_TOKEN_MEDIA_SERVER()
  },
  mounted () {},
  methods: {
    /**
     * Change banner type
     */
    onChangeBannerType: function () {
    },
    /**
     * Upload advertisement image
     */
    uploadBannerImage: async function () {
      let formData = new FormData()
      formData.append('file', this.bannerInfo.imageFile)
      formData.append('directory', 'eVoucher/Portal/BannerSlider')
      formData.append('forceOverride', true)
      await axios({
        method: 'POST',
        timeout: 6000,
        url: this.media_server.domain + '/api/v1/upload/',
        data: formData,
        headers: { Authorization: `JWT ${this.media_server.token}` }
      }).then(
        async function (res) {
          let data = res.data
          this.bannerInfo.imageUrl = data.domain + data.url
        }.bind(this)
      ).catch(
        async function () {
          this.bannerInfo.imageUrl = null
        }.bind(this)
      )
    },
    /**
     * Select img
     */
    onSelectBannerImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.bannerInfo.imageSize = files[0].size
      this.getResultFile(files[0])
      this.bannerInfo.imageUrl = URL.createObjectURL(files[0])
    },
    /**
     * Remove img
     */
    onClickRemoveBannerImg: function () {
      this.bannerInfo.imageUrl = null
      this.bannerInfo.imageFile = null
    },
    getResultFile: function (file) {
      let vm = this
      vm.bannerInfo.imageFile = file
    },
    /**
     * Show modal
     */
    onShowModal: function (bannerId, type) {
      this.setDefaultValue()
      this.bannerInfo.type = type
      if (bannerId) {
        this.bannerInfo.id = bannerId
        this.GET_BANNER_SLIDER_DETAIL({ id: bannerId }).then(
          function (res) {
            let data = res.data
            this.bannerInfo.title = data.title
            this.bannerInfo.description = data.description
            this.bannerInfo.imageUrl = data.image_url
            this.bannerInfo.link = data.link
          }.bind(this)
        )
      }
      this.isShowModal = true
    },
    /**
     * Set default value
     */
    setDefaultValue: function () {
      this.bannerInfo.id = null
      this.bannerInfo.title = null
      this.bannerInfo.description = null
      this.bannerInfo.imageUrl = null
      this.bannerInfo.imageFile = null
      this.positionSelected = null
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: async function () {
      if (this.$refs.form.validate()) {
        if (this.bannerInfo.imageSize > 10000000) {
          this.ON_SHOW_TOAST({
            message: this.$t('common.imageCannotExceed10MB'),
            styleType: 'danger'
          })
        } else {
          let filter = {
            title: this.bannerInfo.title,
            imageUrl: this.bannerInfo.imageUrl,
            description: this.bannerInfo.description,
            type: this.bannerInfo.type,
            link: this.bannerInfo.link,
            position: this.positionSelected
          }
          if (this.bannerInfo.imageFile) {
            await this.uploadBannerImage()
            filter['imageUrl'] = this.bannerInfo.imageUrl
          }
          if (this.bannerInfo.id) {
            this.UPDATE_BANNER_SLIDER({ id: this.bannerInfo.id, ...filter }).then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.isShowModal = false
                this.$emit('addBannerSliderSuccess')
              }.bind(this)
            ).catch(
              function () {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            )
          } else {
            this.ADD_BANNER_SLIDER(filter).then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.isShowModal = false
                this.$emit('addBannerSliderSuccess')
              }.bind(this)
            ).catch(
              function () {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            )
          }
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_TOKEN_MEDIA_SERVER',
      'ADD_BANNER_SLIDER',
      'GET_BANNER_SLIDER_DETAIL',
      'UPDATE_BANNER_SLIDER'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
